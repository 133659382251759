window.addEventListener("scroll", () => {
  let scrollTop = window.scrollY

  if (scrollTop > 40) {
    document.querySelector('body').classList.add('notTop')
  } else {
    document.querySelector('body').classList.remove('notTop')
  }
})


var scrollPos = 0

window.addEventListener('scroll', function(){
  let scrollTop = window.scrollY

  if (scrollTop > 40) {
    if ((document.body.getBoundingClientRect()).top > scrollPos)
      document.querySelector('body').classList.add('notTop')
    else
      document.querySelector('body').classList.remove('notTop')
  
    scrollPos = (document.body.getBoundingClientRect()).top
  }

})


var headers = document.querySelectorAll('.faqLabel')

for(var i = 0; i < headers.length; i++) {
	headers[i].addEventListener('click', openCurrAccordion)
}

function openCurrAccordion(e) {
	for(var i = 0; i < headers.length; i++) {
		var parent = headers[i].parentElement
		var article = headers[i].nextElementSibling

		if (this === headers[i] && !parent.classList.contains('open')) {
			parent.classList.add('open')
		}
		else {
			parent.classList.remove('open');
		}
	}
}